import {
  // useAnalytics,
  useBookmarks,
  useModal,
  useUser,
  useTranslate
} from '~/hooks/actions'
import bookmarkStyles from './BookmarkButton.module.scss'
import { klaviyoTrack } from '~/services/klaviyo'

const BookmarkButton = ({ productId, text, buttonSize, textSize }) => {
  const translate = useTranslate()
  const { bookmarks, setBookmark } = useBookmarks()
  const { user } = useUser()
  const { openModal } = useModal()
  const isBookmarked = !!bookmarks[productId]
  // const { trackBookmarkAdded } = useAnalytics()

  const handleClick = () => {
    if (user.id) {
      setBookmark(productId, !isBookmarked)

      // Klaviyo tracking for bookmark
      // klaviyoTrack({
      //   event: 'Product Bookmarked',
      //   customer_properties: {
      //     email: user.email
      //   },
      //   properties: {
      //     account_id: user.id,
      //     product_id: productId,
      //     hasBookmarked: !isBookmarked,
      //     url: window.location.href
      //   }
      // })

      // Klaviyo tracking for bookmark (New)
      klaviyoTrack({
        data: {
          type: 'event',
          attributes: {
            properties: {
              product_id: productId
            },
            metric: {
              data: {
                type: 'metric',
                attributes: { name: 'Product Bookmarked' }
              }
            },
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  properties: {
                    account_id: user.id,
                    hasBookmarked: !isBookmarked,
                    url: window.location.href
                  },
                  email: user.email
                }
              }
            }
          }
        }
      })
    } else {
      openModal(translate('account.login_modal.bookmark'), () =>
        console.log('Successful login')
      )
    }

    // GA4 tracking for bookmark
    // trackBookmarkAdded({ productSlug: productId })
  }

  return (
    <button
      type="button"
      className={bookmarkStyles.button}
      style={{
        fontSize: textSize ? textSize : 'inherit'
      }}
      onClick={handleClick}>
      {text}{' '}
      <span
        className={`icon ${
          isBookmarked && user.id ? 'icon--bookmark' : 'icon--bookmark-outline'
        }`}
        style={{
          fontSize: buttonSize ? buttonSize : 'inherit'
        }}
      />
    </button>
  )
}

export default BookmarkButton
