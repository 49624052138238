import AuctionTimer from '~/components/Common/AuctionTimer'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useBasta } from '~/hooks/actions'

import pgiStyles from './GridItem.module.scss'

const RenderTimer = ({ bastaSaleId, bastaItemId }) => {
  const clientAuctions = useSelector(state => state.basta.clientAuctions)
  const { getClientAuction } = useBasta()
  const clientItem = clientAuctions[bastaSaleId]
  const itemStatus = clientItem?.status

  useEffect(() => {
    if (!clientAuctions[bastaSaleId]) {
      getClientAuction({ saleId: bastaSaleId })
    }
  }, [])

  if (!itemStatus || itemStatus === 'CLOSED') return

  return (
    <div className={pgiStyles.pgi__timer}>
      <AuctionTimer
        auctionId={bastaSaleId}
        itemId={bastaItemId}
        maxTimerLength={14400000}
      />
    </div>
  )
}

export default RenderTimer
