import { default as NextLink } from 'next/link'
import { useAnalytics } from '~/hooks/actions'

const ProductLink = ({ slug, children, ...props }) => {
  const { trackProductClicked } = useAnalytics()

  return (
    <NextLink passHref href={`/products/${slug}/`}>
      <a
        style={{ display: 'block', width: '100%' }}
        onClick={() => trackProductClicked({ productHandle: slug })}
        {...props}>
        {children}
      </a>
    </NextLink>
  )
}

export default ProductLink
