import ProductGridItem from './Item'
import productGridStyles from './Grid.module.scss'
import classNames from 'classnames'

const ProductGrid = ({ products }) => (
  <div
    className={classNames(
      productGridStyles.items,
      products.length < 5 ? productGridStyles['centered-results'] : '',
      products.length > 0 ? '' : productGridStyles['no-results']
    )}>
    {products.length > 0
      ? products.map((product, index) => (
          <ProductGridItem
            key={index}
            index={index}
            title={
              product?.collections?.length > 0 ? product.collections[0] : ''
            }
            product={product}
            className={productGridStyles.item}
          />
        ))
      : null}
  </div>
)

export default ProductGrid
