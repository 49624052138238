import { default as NextLink } from 'next/link'

const ArticleLink = ({ slug, children, ...props }) => {
  return (
    <NextLink passHref href={`/articles/${slug}/`}>
      <a style={{ display: 'block', width: '100%' }} {...props}>
        {children}
      </a>
    </NextLink>
  )
}

export default ArticleLink
