import { TimerCountdown } from '~/components/Generic/TimerCountdown'
import { useBasta } from '~/hooks/actions/use-basta'

const AuctionTimer = ({
  auctionId,
  itemId,
  fallBackText = 'Soon',
  maxTimerLength
}) => {
  const { basta } = useBasta()
  const clientItem = basta?.clientAuctions[auctionId]?.items?.edges.find(
    item => {
      return item.node.id === itemId
    }
  )
  const managementItem = basta?.auctions[auctionId]?.items?.edges.find(item => {
    return item.node.id === itemId
  })
  const status = clientItem?.node.status || basta?.auctions[auctionId]?.status
  const closingDate =
    managementItem?.node?.dates?.closingEnd ||
    clientItem?.node?.itemDates?.closingEnd
  const openingDate =
    basta?.auctions[auctionId]?.dates?.openDate ||
    basta?.clientAuctions[auctionId]?.dates?.openDate
  const closingTimestamp = clientItem?.node?.itemDates?.closingEnd
  if (status === 'PUBLISHED' || status === 'ITEM_NOT_OPEN') {
    return (
      <TimerCountdown
        fallBackText={fallBackText || 'Soon'}
        endDate={openingDate}
        maxTimerLength={maxTimerLength}
      />
    )
  } else {
    return status === 'ITEM_CLOSING' || status === 'CLOSING' ? (
      closingTimestamp ? (
        <TimerCountdown
          fallBackText={fallBackText}
          endDate={closingTimestamp}
          maxTimerLength={maxTimerLength}
        />
      ) : (
        <span>{fallBackText}</span>
      )
    ) : (
      <TimerCountdown
        fallBackText={'Soon'}
        endDate={closingDate}
        maxTimerLength={maxTimerLength}
      />
    )
  }
}

export default AuctionTimer
