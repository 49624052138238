import { useEffect, useState } from 'react'
import { useBasta, useTranslate, useUser } from '~/hooks/actions'
import { motion, useAnimationControls } from 'framer-motion'

import pgiStyles from './GridItem.module.scss'
import { useExchange } from '~/context/exchange'

import RenderTimer from './RenderTimer'

const RenderInfo = ({
  clientAuction,
  bidAmount,
  buyersFee,
  startingPrice,
  bastaSaleId,
  bastaItemId,
  isMerch,
  estimatedMin,
  estimatedMax,
  price,
  descriptionAnimControls
}) => {
  const translate = useTranslate()
  const { user } = useUser()

  const [prevClientAuctions, setPrevClientAuctions] = useState(null)
  const [showTimer, setShowTimer] = useState(false)
  const [useRedColor, setUseRedColor] = useState(false)
  const arrowAnimControls = useAnimationControls()
  const textAnimControls = useAnimationControls()
  const { convertToCurrency, selectedCurrency } = useExchange()

  const auctionOpeningTimestamp = new Date(clientAuction?.dates?.openDate)

  const auctionClosingEndTimestamp = new Date(
    clientAuction?.items?.edges[0]?.node?.itemDates?.closingEnd
  )

  const localAuctionClosingEndTimestamp =
    auctionClosingEndTimestamp.toLocaleString(undefined, {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    })

  const { getClientAuction } = useBasta()
  let prevClientAuction
  let currentBid = bidAmount
  let totalBids
  let prevTotalBids
  let auctionStatus = ''
  if (clientAuction) {
    currentBid = clientAuction?.items?.edges[0]?.node?.currentBid
    totalBids = clientAuction?.items?.edges[0]?.node?.bids?.length
      ? clientAuction?.items?.edges[0]?.node?.bids?.length
      : clientAuction?.items?.edges[0]?.node?.totalBids
      ? clientAuction?.items?.edges[0]?.node?.totalBids
      : '0'
    auctionStatus = clientAuction?.status
  }

  if (prevClientAuctions?.[bastaSaleId]) {
    prevClientAuction = prevClientAuctions[bastaSaleId]
    prevTotalBids = prevClientAuction?.items?.edges[0]?.node?.bids?.length
      ? prevClientAuction?.items?.edges[0]?.node?.bids?.length
      : prevClientAuction?.items?.edges[0]?.node?.totalBids
      ? prevClientAuction?.items?.edges[0]?.node?.totalBids
      : '0'
  }

  useEffect(() => {
    if (!clientAuction?.status)
      getClientAuction({ saleId: bastaSaleId, userId: user?.id })
  }, [])

  useEffect(() => {
    if (clientAuction) {
      // Store previous auction data for comparison
      setPrevClientAuctions(clientAuction)
    } else {
      getClientAuction({ saleId: bastaSaleId, userId: user?.id })
    }

    // Trigger animation when there's a new bid total
    if (totalBids && prevTotalBids) {
      if (totalBids !== prevTotalBids) {
        arrowAnimControls.start({ opacity: [1, 0], transition: 1 })
        textAnimControls.start({ opacity: [0.25, 1], transition: 1 })
        descriptionAnimControls.start({ opacity: [0.25, 1], transition: 1 })
      }
    }

    let interval = null

    // Re-update showTimer and useRedColor state
    interval = setInterval(() => {
      const currentTimestamp = new Date()

      auctionStatus = clientAuction?.status

      // If auction was closed, update the auction data only once
      if (
        currentTimestamp > auctionClosingEndTimestamp &&
        auctionStatus != 'CLOSED'
      ) {
        getClientAuction({ saleId: bastaSaleId })
        auctionStatus = clientAuction?.status
        clearInterval(interval)

        setShowTimer(false)
        setUseRedColor(false)
      }

      // If auction was opened - update the auction data only once
      if (
        currentTimestamp > auctionOpeningTimestamp &&
        currentTimestamp < auctionClosingEndTimestamp &&
        auctionStatus != 'CLOSING' &&
        auctionStatus != 'OPENED'
      ) {
        getClientAuction({ saleId: bastaSaleId })
        auctionStatus = clientAuction?.status
        clearInterval(interval)
      }

      // Check if auction is closing in less than an hour
      setShowTimer(
        auctionClosingEndTimestamp - currentTimestamp < 3600000 &&
          currentTimestamp < auctionClosingEndTimestamp &&
          currentTimestamp > auctionOpeningTimestamp
      )

      // Check if auction is closing in less than 3 minutes
      setUseRedColor(
        auctionClosingEndTimestamp - currentTimestamp < 60000 * 3 &&
          currentTimestamp < auctionClosingEndTimestamp &&
          currentTimestamp > auctionOpeningTimestamp
      )
    }, 1000)

    return () => clearInterval(interval)
  }, [clientAuction])

  if (!isMerch) {
    // merch or auction item check
    if (auctionStatus === 'OPENED' || auctionStatus === 'CLOSING') {
      // is bidding open for auction item
      if (estimatedMin && estimatedMax) {
        return (
          <div className={pgiStyles.auction_info_wrapper}>
            {/* <motion.span
              className={`${pgiStyles['pgi__update-icon']} icon icon--chevron-up`}
              animate={arrowAnimControls}></motion.span> */}

            {/* Estimate price */}
            <motion.div
              className={pgiStyles.estimate}
              animate={textAnimControls}>
              Estimate ({selectedCurrency}):{' '}
              {convertToCurrency(estimatedMin, 'float')} -{' '}
              {convertToCurrency(estimatedMax, 'float')}
            </motion.div>

            {/* Bid information */}
            {totalBids > 0 ? (
              <motion.div className={pgiStyles.bid} animate={textAnimControls}>
                Current bid ({selectedCurrency}):{' '}
                {convertToCurrency(currentBid)}
              </motion.div>
            ) : (
              <motion.div className={pgiStyles.bid} animate={textAnimControls}>
                Starting bid ({selectedCurrency}):{' '}
                {convertToCurrency(startingPrice, 'float')}
              </motion.div>
            )}

            {/* Lot closes */}
            <motion.div
              className={`
                ${pgiStyles.close_time} 
                ${useRedColor && pgiStyles.close_time_red}
              `}
              animate={textAnimControls}>
              Lot Closes:
              {showTimer ? (
                <RenderTimer
                  bastaSaleId={bastaSaleId}
                  bastaItemId={bastaItemId}
                />
              ) : (
                <span>{localAuctionClosingEndTimestamp}</span>
              )}
            </motion.div>

            {/* Total Bids */}
            <motion.span
              className={pgiStyles.price_count}
              animate={textAnimControls}>
              [{totalBids}]
            </motion.span>
          </div>
        )
      } else {
        return (
          <div>
            <span className="price">{translate('general.enquire')}</span>
          </div>
        )
      }
    } else if (estimatedMin && estimatedMax) {
      if (
        auctionStatus === 'CLOSED' ||
        clientAuction?.items?.edges[0]?.node?.status === 'ITEM_CLOSED'
      ) {
        if (totalBids > 0) {
          return (
            <div>
              {/* <motion.span
                className={`${pgiStyles['pgi__update-icon']} icon icon--chevron-up`}
                animate={arrowAnimControls}></motion.span> */}

              {/* Estimate price */}
              <motion.div
                className={pgiStyles.estimate}
                animate={textAnimControls}>
                Estimate: {convertToCurrency(estimatedMin, 'float')} -{' '}
                {convertToCurrency(estimatedMax, 'float')}
              </motion.div>

              <motion.span className="price" animate={textAnimControls}>
                <span className={pgiStyles.final_price}>
                  Final Price ({selectedCurrency}):
                </span>{' '}
                {convertToCurrency(currentBid + buyersFee)}
              </motion.span>
              <motion.span
                className={pgiStyles.price_count}
                animate={textAnimControls}>
                [{totalBids}]
              </motion.span>
            </div>
          )
        } else {
          return (
            <div>
              {/* Estimate price */}
              <motion.div
                className={pgiStyles.estimate}
                animate={textAnimControls}>
                Estimate ({selectedCurrency}):{' '}
                {convertToCurrency(estimatedMin, 'float')} -{' '}
                {convertToCurrency(estimatedMax, 'float')}
              </motion.div>
              <span className="price">Bidding is closed</span>
              <span className={pgiStyles.price_count}> [0]</span>
            </div>
          )
        }
      } else {
        return (
          <div>
            <span className="price">
              {`Est. ${selectedCurrency}: ${convertToCurrency(
                estimatedMin,
                'float'
              )} - ${convertToCurrency(estimatedMax, 'float')}`}
            </span>
          </div>
        )
      }
    } else {
      return (
        <div>
          <span className="price">{translate('general.private_sale')}</span>
        </div>
      )
    }
  } else {
    return (
      <div>
        <span className="price">
          {selectedCurrency}{' '}
          {convertToCurrency(price, isMerch ? 'float' : 'integer')}
        </span>
      </div>
    )
  }
}

export default RenderInfo
