import ProductLink from '~/components/Product/Link'
import ResponsiveImage from '~/components/Common/ResponsiveImage'
import BookmarkButton from '~/components/Common/BookmarkButton'
import { useBookmarks } from '~/hooks/actions'
import { useSelector } from 'react-redux'
import { useBasta } from '~/hooks/actions'
import { useEffect } from 'react'
import { motion, useAnimationControls } from 'framer-motion'

import pgiStyles from './GridItem.module.scss'

import RenderInfo from './RenderInfo'

const ProductGridItem = ({ index, title, product, className }) => {
  const {
    mainImage,
    name,
    slug,
    price,
    estimatedMin,
    estimatedMax,
    startingPrice,
    bidAmount,
    bastaItemId,
    bastaSaleId,
    auctionStart = '',
    lotNumber,
    vendor,
    useCollectionName,
    collections
  } = product

  const clientAuctions = useSelector(state => state.basta.clientAuctions)
  const { getClientAuction } = useBasta()
  let userWinningAuction =
    clientAuctions[bastaSaleId]?.items?.edges[0]?.node?.bidStatus === 'WINNING'
  let userLosingAuction =
    clientAuctions[bastaSaleId]?.items?.edges[0]?.node?.bidStatus === 'LOSING'
  const variant = product?.variantsCollection?.items[0]

  const isMerch = !lotNumber ? true : false
  const { bookmarks } = useBookmarks()
  const isBookmarked = !!bookmarks[slug]
  const descriptionAnimControls = useAnimationControls()

  const getFees = (estimatedMax, currentBid, setBuyersFee) => {
    let fees
    if (setBuyersFee) {
      if (currentBid) {
        fees = currentBid * (setBuyersFee / 100)
      } else {
        fees = currentBid * (setBuyersFee / 100)
      }
    } else {
      const defaultBuyersPercentage = () => {
        if (estimatedMax < 90000) {
          return 0.1
        } else if (estimatedMax > 90000 && estimatedMax < 200000) {
          return 0.08
        } else {
          return 0.06
        }
      }
      if (currentBid) {
        fees = currentBid * defaultBuyersPercentage()
      } else {
        fees = currentBid * defaultBuyersPercentage()
      }
    }
    return fees
  }

  useEffect(() => {
    if (!clientAuctions[bastaSaleId]) {
      getClientAuction({ saleId: bastaSaleId })
    }
  }, [])

  return (
    <article
      style={{ '--pgi-delay': `${index * 50}ms` }}
      className={`
        ${pgiStyles.pgi} ${className}
        ${
          userWinningAuction
            ? pgiStyles.userWinningBid
            : userLosingAuction
            ? pgiStyles.userLosingBid
            : ''
        }
      `}>
      <ProductLink slug={slug} className={pgiStyles.pgi__link}>
        {mainImage && <ResponsiveImage image={mainImage} maxWidth={500} />}
        <div className={pgiStyles.bidIndicator}>
          <span
            className={
              userWinningAuction
                ? pgiStyles.userWinningBid
                : userLosingAuction
                ? pgiStyles.userLosingBid
                : ''
            }>
            {userWinningAuction
              ? 'The bid is with you'
              : userLosingAuction
              ? 'Outbid'
              : ''}
          </span>
        </div>
      </ProductLink>
      <div className={pgiStyles.pgi__bookmark}>
        <BookmarkButton productId={product.slug} buttonSize={'20px'} />
      </div>
      <div
        className={`${pgiStyles.pgi__info} ${
          isBookmarked ? pgiStyles.pgi__bookmarked : ''
        }`}>
        <ProductLink slug={slug} className={pgiStyles.pgi__desc}>
          <motion.div animate={descriptionAnimControls}>
            <p className="font--body-2">
              {vendor && !useCollectionName
                ? vendor
                : title
                ? title.replaceAll('-', ' ')
                : collections[0]
                ? collections[0].replaceAll('-', ' ')
                : ''}
            </p>
            <p className="font--body-3">{name}</p>
            {!isMerch && lotNumber && (
              <p className="font--body-4">
                <span className="lot">Lot</span>&nbsp;
                <span className="lot_count">{lotNumber}</span>
              </p>
            )}
          </motion.div>
        </ProductLink>
        <div className={`${pgiStyles.pgi__price} font--body-5`}>
          <RenderInfo
            bastaItemId={bastaItemId}
            bastaSaleId={bastaSaleId}
            bidAmount={bidAmount}
            startingPrice={startingPrice}
            auctionStart={auctionStart}
            estimatedMin={estimatedMin}
            estimatedMax={estimatedMax}
            price={price}
            buyersFee={getFees(
              estimatedMax,
              (clientAuctions
                ? clientAuctions[bastaSaleId]?.items?.edges[0]?.node?.currentBid
                : bidAmount) || startingPrice,
              variant?.buyersFeePercentage
            )}
            isMerch={isMerch}
            clientAuction={clientAuctions[bastaSaleId]}
            descriptionAnimControls={descriptionAnimControls}
          />
        </div>
      </div>
    </article>
  )
}
export default ProductGridItem
